import { ActionTree } from 'vuex-typescript-interface';

import { IStore } from './types';

export default {
  async changeSelectedGrowthModel({commit}, value) {
    commit('setSelectedGrowthModel', value);
  },
  async changeSelectedSalesModel({commit}, value) {
    commit('setSelectedSalesModel', value);
  },
  async changeDealSize({commit}, value) {
    commit('setDealSize', value);
  },
  async changeSalesRevenueGrowthPercentage({commit}, value) {
    commit('setSalesRevenueGrowthPercentage', value);
  },
  async changeGrossProfitPercentage({commit}, value) {
    commit('setGrossProfitPercentage', value);
  },
  async changeARRCurrent({commit}, value) {
    commit('setARRCurrent', value);
  },
  async changeARRGoal({commit}, value) {
    commit('setARRGoal', value);
  },
  async changePaidChannelCustomersPercentage({commit}, value) {
    commit('setPaidChannelCustomersPercentage', value);
  },
  async changeMarketingPeopleSpendPercentage({commit}, value) {
    commit('setMarketingPeopleSpendPercentage', value);
  },
  async changeMarketingTechSpendPercentage({commit}, value) {
    commit('setMarketingTechSpendPercentage', value);
  },
  async changeMarketingPromotionsSpendPercentage({commit}, value) {
    commit('setMarketingPromotionsSpendPercentage', value);
  },
} as ActionTree<IStore>;

import { GetterTree } from 'vuex-typescript-interface';
import { IStore, growthOptions, smModelOptions } from './types';

export default {
  avgCAC: (state) => {
    let cac: number = 0;

    switch (state.selectedGrowthModel) {
      case growthOptions.Hubspot:
        cac = 2;
        break;
      case growthOptions.AverageCompany:
        cac = 1.32;
        break;
    }

    return cac;
  },
  avgMarketingSpendPercentage: (state) => {
    let spendRatio = 0;
    switch (state.selectedSalesModel) {
      case smModelOptions.InsideSales:
        spendRatio = 0.34;
        break;
      case smModelOptions.InternetSales:
        spendRatio = 0.69;
        break;
      case smModelOptions.FieldSales:
        spendRatio = 0.29;
        break;
      case smModelOptions.ChannelSales:
        spendRatio = 0.33;
        break;
      case smModelOptions.Mixed:
        spendRatio = 0.34;
        break;
    }

    return spendRatio;
  },
  salesGoal: (state) => (state.ARRCurrent && state.ARRGoal && state.salesRevenueGrowthPercentage)? (state.ARRGoal - state.ARRCurrent) * state.salesRevenueGrowthPercentage : 0,
  expansionRevenueGrowthPercentage: (state) => 1 - state.salesRevenueGrowthPercentage,
  projectedSMBudget: (state, getters) => getters.salesGoal * getters.avgCAC,
  avgSMBudget: (state, getters) => getters.salesGoal * getters.avgCAC * getters.avgMarketingSpendPercentage,
  neededCustomers: (state, getters) => getters.salesGoal / state.dealSize,
  newCustomerCAC: (state, getters) => getters.avgSMBudget / getters.neededCustomers,
  blendedSpendRevenuePercentage: (state, getters) => (state.dealSize)? getters.newCustomerCAC / state.dealSize : 0,
  paidChannelCAC: (state, getters) => (state.paidChannelCustomersPercentage)? getters.marketingPromotionsSpend / (state.paidChannelCustomersPercentage * getters.neededCustomers) : 0,
  marketingPeopleSpend: (state, getters) => (getters.avgSMBudget)? getters.avgSMBudget * state.marketingPeopleSpendPercentage : 0,
  marketingTechSpend: (state, getters) => (getters.avgSMBudget)? getters.avgSMBudget * state.marketingTechSpendPercentage : 0,
  marketingPromotionsSpend: (state, getters) => (getters.avgSMBudget)? getters.avgSMBudget * state.marketingPromotionsSpendPercentage: 0,
} as GetterTree<IStore>;

import { Resolvable, StateInputFor } from 'vuex-typescript-interface';

import { IStore, growthOptions, smModelOptions } from '@/store/types';

const state: Resolvable<StateInputFor<IStore>> = () => {
  const out: StateInputFor<IStore> = {
    selectedGrowthModel: growthOptions.Hubspot,
    selectedSalesModel: smModelOptions.InternetSales,
    dealSize: 60000,
    salesRevenueGrowthPercentage: 0.9,
    grossProfitPercentage: 0.8,
    ARRCurrent: 4000000,
    ARRGoal: 8000000,
    paidChannelCustomersPercentage: 0.5,
    marketingPeopleSpendPercentage: 0.2,
    marketingTechSpendPercentage: 0.1,
    marketingPromotionsSpendPercentage: 0.7,
  };

  return out;
};

export default state;














































import Vue from 'vue';

import SiteFooter from '@/components/site-footer.vue';

export default Vue.extend({
  components: {
    SiteFooter,
  },
});
